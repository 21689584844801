<template>
    <div>
        <div id="featured-title" class="clearfix featured-title-left">
            <div id="featured-title-inner" class="container clearfix">
                <div class="featured-title-inner-wrap">
                    <div class="featured-title-heading-wrap">
                        <h1 class="featured-title-heading">About Us</h1>
                    </div>
                    <div id="breadcrumbs">
                        <div class="breadcrumbs-inner">
                            <div class="breadcrumb-trail">
                                <router-link to="/" title="Construction" rel="home" class="trail-begin">Home</router-link>
                                <span class="sep">/</span>
                                <span class="trail-end">About Us</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="main-content" class="site-main clearfix">
            <div id="content-wrap">
                <div id="site-content" class="site-content clearfix">
                    <div id="inner-content" class="inner-content-wrap">
                        <div class="page-content">
                            <section class="wprt-section">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="100" data-mobi="40" data-smobi="40"></div>
                                            <h2 class="text-center margin-bottom-20">OUR HISTORY & FACTS</h2>
                                            <div class="wprt-lines style-2 custom-1">
                                                <div class="line-1"></div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="36" data-mobi="30" data-smobi="30"></div>

                                            <p class="wprt-subtitle">Everything has a history<br> And so began, our history and facts in the construction industry</p>

                                            <div class="wprt-spacer" data-desktop="40" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-6">
                                            <h3 class="line-height-normal margin-bottom-10">WHY CHOOSE US</h3>
                                            <div class="wprt-lines style-1 custom-3">
                                                <div class="line-1"></div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="25" data-mobi="25" data-smobi="25"></div>

                                            <p class=" margin-bottom-25">
                                                The history of construction embraces many other fields like structural engineering, 
                                                Civil engineering, cities growing and Population growth that are relatives to branches 
                                                of Technology science, history, and architecture to investigate the buildings conservation 
                                                and recorded their accomplishments. Those fields permit use to analyze modern or Latest 
                                                construction and prehistoric constructions, as their structures, building Materials, 
                                                and tools used.
                                            </p>
                                            <p>
                                                History of building is evolving by different trends in time, marked by few key principles: 
                                                durability of the materials used, the increasing of height and span, the degree of control 
                                                exercised over the interior environment and finally the energy available to the construction process.
                                            </p>


                                            <router-link to="/contacts" class="wprt-button small rounded-3px" href="#">Contact Us</router-link>

                                            <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="wprt-galleries galleries w-570px" data-width="135" data-margin="10">
                                                <div id="wprt-slider" class="flexslider">
                                                    <ul class="slides">
                                                        <li class="flex-active-slide">
                                                            <a class="zoom" href="assets/img/gallery/1.jpg"><i class="fa fa-arrows-alt"></i></a>
                                                            <img src="/assets/img/gallery/1.jpg" alt="image" />
                                                        </li>

                                                        <li class="flex-active-slide">
                                                            <a class="zoom" href="assets/img/gallery/2.jpg"><i class="fa fa-arrows-alt"></i></a>
                                                            <img src="/assets/img/gallery/2.jpg" alt="image" />
                                                        </li>

                                                        <li class="flex-active-slide">
                                                            <a class="zoom" href="assets/img/gallery/3.jpg"><i class="fa fa-arrows-alt"></i></a>
                                                            <img src="/assets/img/gallery/3.jpg" alt="image" />
                                                        </li>

                                                        <li class="flex-active-slide">
                                                            <a class="zoom" href="assets/img/gallery/4.jpg"><i class="fa fa-arrows-alt"></i></a>
                                                            <img src="/assets/img/gallery/4.jpg" alt="image" />
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div id="wprt-carousel" class="flexslider">
                                                    <ul class="slides">
                                                        <li><img src="/assets/img/gallery/1-s.jpg" alt="image"></li>
                                                        <li><img src="/assets/img/gallery/2-s.jpg" alt="image"></li>
                                                        <li><img src="/assets/img/gallery/3-s.jpg" alt="image"></li>
                                                        <li><img src="/assets/img/gallery/4-s.jpg" alt="image"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section class="wprt-section about-page parallax">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="120" data-mobi="60" data-smobi="60"></div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="wprt-counter text-center accent-type">
                                                <div class="number" data-speed="5000" data-to="1240" data-in-viewport="yes">1240</div>
                                                <div class="text">PROJECT COMPLETED</div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="wprt-counter text-center accent-type has-plus">
                                                <div class="number" data-speed="5000" data-to="1750" data-in-viewport="yes">1750</div>
                                                <div class="text">HAPPY CLIENTS</div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="wprt-counter text-center accent-type">
                                                <div class="number" data-speed="5000" data-to="984" data-in-viewport="yes">984</div>
                                                <div class="text">WORKERS EMPLOYED</div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="wprt-counter text-center accent-type">
                                                <div class="number" data-speed="5000" data-to="96" data-in-viewport="yes">96</div>
                                                <div class="text">AWARDS WON</div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="120" data-mobi="60" data-smobi="60"></div>
                                        </div>



                                    </div>
                                </div>
                            </section>

                            <Team />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Team from '@/components/Team.vue'
export default {
    name: 'About',
    components: {
        Team
    }
}
</script>