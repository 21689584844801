<template>
    <div>
        <div id="featured-title" class="clearfix featured-title-left">
            <div id="featured-title-inner" class="container clearfix">
                <div class="featured-title-inner-wrap">
                    <div class="featured-title-heading-wrap">
                        <h1 class="featured-title-heading">Contact Us</h1>
                    </div>
                    <div id="breadcrumbs">
                        <div class="breadcrumbs-inner">
                            <div class="breadcrumb-trail">
                                <router-link to="/" title="Construction" rel="home" class="trail-begin">Home</router-link>
                                <span class="sep">/</span>
                                <span class="trail-end">Contact Us</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="main-content" class="site-main clearfix">
            <div id="content-wrap">
                <div id="site-content" class="site-content clearfix">
                    <div id="inner-content" class="inner-content-wrap">
                        <div class="page-content">
                            <section class="wprt-section">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h2 class="text-center margin-bottom-20">GET IN TOUCH WITH US</h2>
                                            <div class="wprt-lines style-2 custom-1">
                                                <div class="line-1"></div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="36" data-mobi="30" data-smobi="30"></div>

                                            <p class="wprt-subtitle">Get in touch with us for your construction projet. Our team is available 24/7 for consulting and execution</p>
                                            <div class="wprt-spacer" data-desktop="40" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-4">
                                            <h5>Address</h5>
                                            <p>Boulevard du 13 Janvier<br> Lome, Togo</p>

                                            <div class="wprt-spacer" data-desktop="15" data-mobi="0" data-smobi="0"></div>

                                            <h5>Phone number</h5>
                                            <p>(+228) 22 22 69 89 | (+228) 91 85 09 44</p>

                                            <div class="wprt-spacer" data-desktop="15" data-mobi="0" data-smobi="0"></div>

                                            <h5>E-mail address</h5>
                                            <p>construction@daragroups.com</p>

                                            <div class="wprt-spacer" data-desktop="0" data-mobi="10" data-smobi="10"></div>
                                        </div>

                                        <div class="col-md-8">
                                            <form class="wprt-contact-form" method="post" @submit.prevent="sendMessage">
                                                <div class="inner">
                                                    <div class="left-side">
                                                        <div class="input-wrap">
                                                            <div class="text-danger" v-if="errors.fullName">{{errors.fullName}}</div>
                                                            <input type="text" tabindex="1" placeholder="Full Name *" v-model="userContact.fullName">
                                                        </div>
                                                        <div class="input-wrap">
                                                            <div class="text-danger" v-if="errors.email">{{errors.email}}</div>
                                                            <input type="text" tabindex="2" placeholder="Email *" v-model="userContact.email">
                                                        </div>
                                                        <div class="input-wrap">
                                                            <div class="text-danger" v-if="errors.subject">{{errors.subject}}</div>
                                                            <input type="text" tabindex="4" placeholder="Subject *" v-model="userContact.subject">
                                                        </div>
                                                        <div class="message-wrap">
                                                            <div class="text-danger" v-if="errors.message">{{errors.message}}</div>
                                                            <textarea class="" tabindex="5" placeholder="Message *" v-model="userContact.message"></textarea>
                                                        </div>
                                                        <div class="send-wrap">
                                                            <input type="submit" value="SEND MESSAGE" class="submit">
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="100" data-mobi="60" data-smobi="60"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section id="partners" class="wprt-section">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="50" data-mobi="50" data-smobi="40"></div>

                                            <div class="wprt-partners">
                                                <div class="owl-carousel">
                                                    <div class="partner">
                                                        <a target="_blank" href="#"><img src="/assets/img/partners/1.png" alt="image" /></a>
                                                    </div>

                                                    <div class="partner">
                                                        <a target="_blank" href="#"><img src="/assets/img/partners/2.png" alt="image" /></a>
                                                    </div>

                                                    <div class="partner">
                                                        <a target="_blank" href="#"><img src="/assets/img/partners/3.png" alt="image" /></a>
                                                    </div>

                                                    <div class="partner">
                                                        <a target="_blank" href="#"><img src="/assets/img/partners/4.png" alt="image" /></a>
                                                    </div>

                                                    <div class="partner">
                                                        <a target="_blank" href="#"><img src="/assets/img/partners/5.png" alt="image" /></a>
                                                    </div>

                                                    <div class="partner">
                                                        <a target="_blank" href="#"><img src="/assets/img/partners/1.png" alt="image" /></a>
                                                    </div>

                                                    <div class="partner">
                                                        <a target="_blank" href="#"><img src="/assets/img/partners/2.png" alt="image" /></a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="50" data-mobi="50" data-smobi="40"></div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import validator from 'validator'
import { toast } from 'bulma-toast'

export default {
    name: 'Contacts',
    data() {
        return {
            errors: {},
            messageRes: null,
            userContact: {
                fullName: '',
                email: '',
                subject: '',
                message: ''
            }
        }
    },
    methods: {
        sendMessage() {
            this.errors = {}
            if(validator.isEmpty(this.userContact.fullName)) this.errors.fullName = 'Full name is required!'
            if(validator.isEmpty(this.userContact.email)) this.errors.email = 'Email is required!'
            if(!validator.isEmail(this.userContact.email)) this.errors.email = 'Email must be valid!'
            if(validator.isEmpty(this.userContact.subject)) this.errors.subject = 'Subject is required!'
            if(validator.isEmpty(this.userContact.message)) this.errors.message = 'Message is required!'
            if (!Object.keys(this.errors).length) {
                let self = this
                axios
                    .post(`${process.env.VUE_APP_API}/api/send/mail`, this.userContact)
                    .then(res => {
                        self.messageRes = res.data.message
                        this.showMessage()
                        self.resetForm()
                    })
                    .catch(err => {})
            }
        },
        resetForm() {
            this.userContact = {}
        }, 
        showMessage() {
            toast({
                message: this.messageRes,
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right'
            })
        }
    }
}
</script>