<template>
    <div id="site-header-wrap">
        <div id="top-bar">
            <div id="top-bar-inner" class="container">
                <div class="top-bar-inner-wrap">
                    <div class="top-bar-content">
                        <span id="top-bar-text">
                            <i class="fa fa-map-marker"></i>Boulevard du 13 Janvier, Lome, Togo
                            <i class="fa fa-phone"></i>(+228) 22 22 69 89
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <header id="site-header" class="header-front-page style-5">
            <div id="site-header-inner" class="container">
                <div class="wrap-inner">          
                    <div id="site-logo" class="clearfix">
                        <div id="site-logo-inner">
                            <router-link to="/" title="Construction" rel="home" class="main-logo">
                                <img src="/assets/img/logo-light-1.png" alt="Construction" data-retina="assets/img/logo-light-1.png" data-width="204" data-height="30">
                            </router-link>
                        </div>
                    </div>
                    <div class="mobile-button"><span></span></div>

                    <nav id="main-nav" class="main-nav">
                        <ul class="menu">
                            <li class="menu-item"><router-link to="/">Home</router-link></li>
                            <li class="menu-item"><router-link to="/about">About</router-link></li>
                            <li class="menu-item"><router-link to="/services">Services</router-link></li>
                            <li class="menu-item"><router-link to="/portfolio">Portfolio</router-link></li>
                            <li class="menu-item"><router-link to="/contacts">Contacts</router-link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
export default {
    name: 'TopNav'
}
</script>