<template>
    <section id="team" class="wprt-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="wprt-spacer" data-desktop="100" data-mobi="40" data-smobi="40"></div>
                    <h2 class="text-center margin-bottom-20">OUR TEAM</h2>
                    <div class="wprt-lines style-2 custom-1">
                        <div class="line-1"></div>
                    </div>

                    <div class="wprt-spacer" data-desktop="36" data-mobi="30" data-smobi="30"></div>

                    <p class="wprt-subtitle">
                        Our engineers and technicians with many years of experience. Their judgement and approach on 
                        every aspect of every project is no doubting
                    </p>

                    <div class="wprt-spacer" data-desktop="40" data-mobi="30" data-smobi="30"></div>

                    <div class="wprt-team has-bullets bullet-style-1" data-layout="slider" data-column="4" data-column2="3" data-column3="2" data-column4="1" data-gaph="30" data-gapv="30">
                        <div id="team-wrap" class="cbp">
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/1.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="twitter"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                <li class="linkedin"><a target="_blank" href="#"><i class="fa fa-linkedin"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Marie Namesse</h4>
                                            <div class="position">Chief Executive Officer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/2.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="facebook"><a target="_blank" href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li class="twitter"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Richards</h4>
                                            <div class="position">Chief Architect</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/3.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="facebook"><a target="_blank" href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li class="twitter"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">John Halpern</h4>
                                            <div class="position">Civil Engineer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/4.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="facebook"><a target="_blank" href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                <li class="linkedin"><a target="_blank" href="#"><i class="fa fa-linkedin"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Tommy Atkins</h4>
                                            <div class="position">Construction Manager</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/1.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="facebook"><a target="_blank" href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li class="twitter"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">John Joe</h4>
                                            <div class="position">Junior Architect</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/2.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="twitter"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                <li class="linkedin"><a target="_blank" href="#"><i class="fa fa-linkedin"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Richard Wagner</h4>
                                            <div class="position">Civil Engineer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/3.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="facebook"><a target="_blank" href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li class="twitter"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Sarah Spence</h4>
                                            <div class="position">Construction Assistant</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/4.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="facebook"><a target="_blank" href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li class="twitter"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">John Halpern</h4>
                                            <div class="position">Construction Manager</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cbp-item">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src="/assets/img/team/1.jpg" alt="image" />
                                            </div>
                                                <ul class="socials clearfix">
                                                <li class="facebook"><a target="_blank" href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li class="google-plus"><a target="_blank" href="#"><i class="fa fa-google-plus"></i></a></li>
                                                <li class="linkedin"><a target="_blank" href="#"><i class="fa fa-linkedin"></i></a></li>
                                                </ul>
                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Tommy Atkins</h4>
                                            <div class="position">Electricians</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="wprt-spacer" data-desktop="70" data-mobi="50" data-smobi="40"></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Team'
}
</script>