<template>
    <div>
        <footer id="footer">
            <div id="footer-widgets" class="container style-1">
                <div class="row">
                    <div class="col-md-3">
                        <div class="widget widget_about margin-bottom-25">
                            <div class="margin-bottom-35">
                                <img src="/assets/img/logo-light-1.png" width="204" height="30" alt="image" />
                            </div>
                            <p>
                                Constructon, For The People By The People.
                            </p>
                        </div>

                        <div class="widget widget_information">
                            <ul class="info-wrap">
                                <li class="address item">Boulevard du 13 Janvier</li>
                                <li class="phone item">(+228) 22 22 69 89, (+228) 91 85 09 44</li>
                                <li class="email item">info@construction.daragroups.com</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="widget widget_tag_cloud">
                            <h2 class="widget-title">Popular tags</h2>
                            <div class="tagcloud">
                                <router-link to="/portfolio" href="#">Architectural</router-link>
                                <router-link to="/portfolio" href="#">Builder</router-link>
                                <router-link to="/portfolio" href="#">Building</router-link>
                                <router-link to="/portfolio" href="#">Garden design</router-link>
                                <router-link to="/portfolio" href="#">Interior</router-link>
                                <router-link to="/portfolio" href="#">Construction</router-link>
                                <router-link to="/portfolio" href="#">Exterior</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="widget widget_links">
                            <h2 class="widget-title">Useful links</h2>
                            
                            <ul class="links col2">
                                <li><router-link to="/">Home</router-link></li>
                                <li><router-link to="/about">About</router-link></li>
                                <li><router-link to="/services">Services</router-link></li>
                                <li><router-link to="/portfolio">Portfolio</router-link></li>
                                <li class="last"><router-link to="/contacts">Contacts</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="widget widget_instagram">
                            <h2 class="widget-title">Instagram photos</h2>

                            <div class="instagram-wrap clearfix g10">
                                <div class="instagram_badge_image">
                                    <a href="#">
                                        <div class="item">
                                            <img src="/assets/img/instagram/1.jpg" alt="image" />
                                        </div>
                                    </a>
                                </div>
                                <div class="instagram_badge_image">
                                    <a href="#">
                                        <div class="item">
                                            <img src="/assets/img/instagram/2.jpg" alt="image" />
                                        </div>          
                                    </a>
                                </div>
                                <div class="instagram_badge_image">
                                    <a href="#">
                                        <div class="item">
                                            <img src="/assets/img/instagram/3.jpg" alt="image" />
                                        </div>          
                                    </a>
                                </div>
                                <div class="instagram_badge_image">
                                    <a href="#">
                                        <div class="item">
                                            <img src="/assets/img/instagram/4.jpg" alt="image" />
                                        </div>          
                                    </a>
                                </div>
                                <div class="instagram_badge_image">
                                    <a href="#">
                                        <div class="item">
                                            <img src="/assets/img/instagram/5.jpg" alt="image" />
                                        </div>          
                                    </a>
                                </div>
                                <div class="instagram_badge_image">
                                    <a href="#">
                                        <div class="item">
                                            <img src="/assets/img/instagram/6.jpg" alt="image" />
                                        </div>          
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <div id="bottom" class="clearfix style-1">
            <div id="bottom-bar-inner" class="wprt-container">
                <div class="bottom-bar-inner-wrap">
                
                    <div class="bottom-bar-content">
                        <div id="copyright">DARA CONSTRUCTION - DIGITAL CONSTRUCTION
                        </div>
                    </div>

                    <div class="bottom-bar-menu">
                        <ul class="bottom-nav">
                            <li><router-link to="/">HOME</router-link></li>
                            <li><router-link to="/about">ABOUT</router-link></li>
                            <li><router-link to="/services">SERVICES</router-link></li>
                            <li><router-link to="/portfolio">PORTFOLIO</router-link></li>
                            <li><router-link to="/contacts">CONTACTS</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer'
}
</script>