<template>
    <div>
        <div id="featured-title" class="clearfix featured-title-left">
            <div id="featured-title-inner" class="container clearfix">
                <div class="featured-title-inner-wrap">
                    <div class="featured-title-heading-wrap">
                        <h1 class="featured-title-heading">Our Portfolio</h1>
                    </div>
                    <div id="breadcrumbs">
                        <div class="breadcrumbs-inner">
                            <div class="breadcrumb-trail">
                                <router-link to="/" title="Construction" rel="home" class="trail-begin">Home</router-link>
                                <span class="sep">/</span>
                                <span class="trail-end">Our Portfolio</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="main-content" class="site-main clearfix">
            <div id="content-wrap">
                <div id="site-content" class="site-content clearfix">
                    <div id="inner-content" class="inner-content-wrap">
                        <div class="page-content">
                            <section class="wprt-section">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="70" data-mobi="40" data-smobi="40"></div>
                                            <h2 class="text-center font-size-28 margin-bottom-10">PORTFOLIO</h2>
                                            <div class="wprt-lines style-2 custom-1">
                                                <div class="line-1"></div>
                                            </div>
                                            <div class="wprt-spacer" data-desktop="25" data-mobi="25" data-smobi="25"></div>
                                            <p class="wprt-subtitle">Some of our completed construction projects.</p>
                                            <div class="wprt-spacer" data-desktop="43" data-mobi="30" data-smobi="30"></div>
                                        </div>
                                                
                                        <div class="col-md-12">
                                            <div class="wprt-project has-bullets bullet-style-2 has-arrows arrow-style-1 arrow-position-2" data-layout="slider" data-column="4" data-column2="3" data-column3="2" data-column4="1" data-gaph="15" data-gapv="15">
                                                <div id="projects" class="cbp">
                                                    <div class="cbp-item">
                                                        <div class="project-item">
                                                            <div class="inner">
                                                                <div class="grid">
                                                                <figure class="effect-sadie">
                                                                    <img src="/assets/img/projects/1.jpg" alt="image" />
                                                                    <figcaption>
                                                                        <div>
                                                                            <h2><a href="#">LUXURY BUILDINGS</a></h2>
                                                                            <p>Construction</p>
                                                                        </div>
                                                                    </figcaption>           
                                                                </figure>
                                                                </div>

                                                                <a class="project-zoom cbp-lightbox" href="#" data-title="LUXURY BUILDINGS">
                                                                    <i class="fa fa-arrows-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="project-item">
                                                            <div class="inner">
                                                                <figure class="effect-honey">
                                                                    <img src="/assets/img/projects/2.jpg" alt="image" />
                                                                    <figcaption>
                                                                        <div>
                                                                            <h2><a href="#">LUXURY BUILDINGS</a></h2>
                                                                            <p>Construction</p>
                                                                        </div>
                                                                    </figcaption>           
                                                                </figure>

                                                                <a class="project-zoom cbp-lightbox" href="#" data-title="LUXURY BUILDINGS">
                                                                    <i class="fa fa-arrows-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="project-item">
                                                            <div class="inner">
                                                                <figure class="effect-sadie">
                                                                    <img src="/assets/img/projects/3.jpg" alt="image" />
                                                                    <figcaption>
                                                                        <div>
                                                                            <h2><a  href="#">LUXURY BUILDINGS</a></h2>
                                                                            <p>Construction</p>
                                                                        </div>
                                                                    </figcaption>           
                                                                </figure>

                                                                <a class="project-zoom cbp-lightbox" href="assets/img/projects/3-full.html" data-title="LUXURY BUILDINGS">
                                                                    <i class="fa fa-arrows-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="project-item">
                                                            <div class="inner">
                                                                <figure class="effect-zoe">
                                                                    <img src="/assets/img/projects/1.jpg" alt="image" />
                                                                    <figcaption>
                                                                        <div>
                                                                            <h2><a href="#">LUXURY BUILDINGS</a></h2>
                                                                            <p>Construction</p>
                                                                        </div>
                                                                    </figcaption>           
                                                                </figure>

                                                                <a class="project-zoom cbp-lightbox" href="#" data-title="LUXURY BUILDINGS">
                                                                    <i class="fa fa-arrows-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="project-item">
                                                            <div class="inner">
                                                                <figure class="effect-oscar">
                                                                    <img src="/assets/img/projects/2.jpg" alt="image" />
                                                                    <figcaption>
                                                                        <div>
                                                                            <h2><a href="#">LUXURY BUILDINGS</a></h2>
                                                                            <p>Construction</p>
                                                                        </div>
                                                                    </figcaption>           
                                                                </figure>

                                                                <a class="project-zoom cbp-lightbox" href="#" data-title="LUXURY BUILDINGS">
                                                                    <i class="fa fa-arrows-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="project-item">
                                                            <div class="inner">
                                                                <figure class="effect-sadie">
                                                                    <img src="/assets/img/projects/3.jpg" alt="image" />
                                                                    <figcaption>
                                                                        <div>
                                                                            <h2><a href="#">LUXURY BUILDINGS</a></h2>
                                                                            <p>Construction</p>
                                                                        </div>
                                                                    </figcaption>           
                                                                </figure>

                                                                <a class="project-zoom cbp-lightbox" href="assets/img/projects/3-full.html" data-title="LUXURY BUILDINGS">
                                                                    <i class="fa fa-arrows-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="project-item">
                                                            <div class="inner">
                                                                <div class="grid">
                                                                <figure class="effect-sadie">
                                                                    <img src="/assets/img/projects/1.jpg" alt="image" />
                                                                    <figcaption>
                                                                        <div>
                                                                            <h2><a href="#">LUXURY BUILDINGS</a></h2>
                                                                            <p>Construction</p>
                                                                        </div>
                                                                    </figcaption>           
                                                                </figure>
                                                                </div>

                                                                <a class="project-zoom cbp-lightbox" href="#" data-title="LUXURY BUILDINGS">
                                                                    <i class="fa fa-arrows-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="80" data-mobi="40" data-smobi="40"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Portofolio',
}
</script>