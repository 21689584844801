<template>
    <div>
        <div id="featured-title" class="clearfix featured-title-left">
            <div id="featured-title-inner" class="container clearfix">
                <div class="featured-title-inner-wrap">
                    <div class="featured-title-heading-wrap">
                        <h1 class="featured-title-heading">Our Services</h1>
                    </div>
                    <div id="breadcrumbs">
                        <div class="breadcrumbs-inner">
                            <div class="breadcrumb-trail">
                                <router-link to="/" title="Construction" rel="home" class="trail-begin">Home</router-link>
                                <span class="sep">/</span>
                                <span class="trail-end">Our Services</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="main-content" class="site-main clearfix">
            <div id="content-wrap">
                <div id="site-content" class="site-content clearfix">
                    <div id="inner-content" class="inner-content-wrap">
                        <div class="page-content">
                            <section class="wprt-section services">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40"></div>
                                        </div>
                                                
                                        <div class="col-md-4">
                                            <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                                <div class="icon-wrap font-size-35">
                                                    <span class="dd-icon icon-drawing"></span>
                                                </div>
                                                <div class="content-wrap">
                                                    <h3 class="dd-title font-size-18"><a href="#">Construction Consultant</a></h3>
                                                    <p>
                                                        We provide guidance and advice or building and house construction.
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="43" data-mobi="30" data-smobi="30"></div>

                                            <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                                <div class="icon-wrap font-size-35">
                                                    <span class="dd-icon icon-engineer"></span>
                                                </div>
                                                <div class="content-wrap">
                                                    <h3 class="dd-title font-size-18"><a href="#">General Contracting</a></h3>
                                                    <p>
                                                        For any general contract base project, we offer quality and durable construction services.
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                                <div class="icon-wrap font-size-35">
                                                    <span class="dd-icon icon-drawing-compass"></span>
                                                </div>
                                                <div class="content-wrap">
                                                    <h3 class="dd-title font-size-18"><a href="#">Architectural Design</a></h3>
                                                    <p>
                                                        Any construction project requires and needs an architectural design. It's as simple as that.
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="43" data-mobi="30" data-smobi="30"></div>

                                            <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                                <div class="icon-wrap font-size-35">
                                                    <span class="dd-icon icon-drill-2"></span>
                                                </div>
                                                <div class="content-wrap">
                                                    <h3 class="dd-title font-size-18"><a href="#">Resconstruction Services</a></h3>
                                                    <p>
                                                        Reconstruction is a double work, and we know what to do to prevent it in the future
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                                <div class="icon-wrap font-size-35">
                                                    <span class="dd-icon icon-light-bulb"></span>
                                                </div>
                                                <div class="content-wrap">
                                                    <h3 class="dd-title font-size-18"><a href="#">Electrical System</a></h3>
                                                    <p>For wiring up every construction project, we have experienced electrical engineers</p>
                                                </div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="43" data-mobi="30" data-smobi="30"></div>

                                            <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                                <div class="icon-wrap font-size-35">
                                                    <span class="dd-icon icon-pipe-11"></span>
                                                </div>
                                                <div class="content-wrap">
                                                    <h3 class="dd-title font-size-18"><a href="#">Plumbilg Services</a></h3>
                                                    <p>
                                                        Leave your leaking pipes and other related plumbing issues to us, everyting will be fixed.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="92" data-mobi="40" data-smobi="40"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section id="promotion" class="wprt-section">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="wprt-spacer" data-desktop="8" data-mobi="0" data-smobi="0"></div>
                                            <h2 class="text-white text-center-mobile font-size-20 margin-bottom-0">Contractors & Construction Managers Since 1981</h2>
                                            <div class="wprt-spacer" data-desktop="0" data-mobi="20" data-smobi="20"></div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="text-right text-center-mobile"><router-link to="/contacts" class="wprt-button white rounded-3px">GET A QUOTE</router-link></div>
                                        </div>

                                    </div>
                                </div>
                            </section>

                            <section class="wprt-section offer">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="100" data-mobi="60" data-smobi="60"></div>

                                            <h2>WHAT WE OFFER</h2>
                                            <div class="wprt-lines style-1 custom-2">
                                                <div class="line-1"></div>
                                            </div>

                                            <div class="wprt-spacer" data-desktop="50" data-mobi="30" data-smobi="30"></div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="wprt-service arrow-style-2 has-arrows arrow60 arrow-light" data-layout="slider" data-column="3" data-column2="3" data-column3="2" data-column4="1" data-gaph="30" data-gapv="30">
                                                <div id="service-wrap" class="cbp">
                                                    <div class="cbp-item">
                                                        <div class="service-item clearfix">
                                                            <div class="thumb"><img src="/assets/img/services/7.jpg" alt="image" /></div>
                                                            <div class="service-item-wrap">
                                                                <h3 class="title font-size-18"><a href="#">Construction Consultant</a></h3>
                                                                <p>We provide guidance and advice or building and house construction.</p>
                                                                <router-link to="/portfolio" class="wprt-button small rounded-3px">READ MORE</router-link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="service-item clearfix">
                                                            <div class="thumb"><img src="/assets/img/services/10.jpg" alt="image" /></div>
                                                            <div class="service-item-wrap">
                                                                <h3 class="title font-size-18"><a href="#">Green Building</a></h3>
                                                                <p>
                                                                    Green building refers to both a structure and the application of processes that are 
                                                                    environmentally responsible.
                                                                </p>
                                                                <router-link to="/portfolio" class="wprt-button small rounded-3px">READ MORE</router-link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="service-item clearfix">
                                                            <div class="thumb"><img src="/assets/img/services/8.jpg" alt="image" /></div>
                                                            <div class="service-item-wrap">
                                                                <h3 class="title font-size-18"><a href="#">Resconstruction Services</a></h3>
                                                                <p>Reconstruction is a double work, and we know what to do to prevent it in the future</p>
                                                                <router-link to="/portfolio" class="wprt-button small rounded-3px">READ MORE</router-link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="cbp-item">
                                                        <div class="service-item clearfix">
                                                            <div class="thumb"><img src="/assets/img/services/11.jpg" alt="image" /></div>
                                                            <div class="service-item-wrap">
                                                                <h3 class="title font-size-18"><a href="#">Roofing & Flooring Services</a></h3>
                                                                <p>
                                                                    We offer quality services and materials to make your roof and floor standing against sunshine and rain.
                                                                </p>
                                                                <router-link to="/portfolio" class="wprt-button small rounded-3px">READ MORE</router-link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="wprt-spacer" data-desktop="100" data-mobi="40" data-smobi="40"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Services'
}
</script>