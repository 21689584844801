<template>
  <div>
    <div class="rev_slider_wrapper fullwidthbanner-container">
        <div id="rev-slider1" class="rev_slider fullwidthabanner">
            <ul>
                <li data-transition="fade" data-slotamount="default" data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed="1500" data-thumb="assets/img/slider/1.jpg" data-rotate="0" data-saveperformance="off" data-title="CONSTRUCTION">
                    <img src="/assets/img/slider/1.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-no-retina>
                    <div class="tp-caption tp-resizeme text-white font-family-heading text-shadow font-weight-500"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['-50','-40','-30','-20']"
                        data-fontsize="['60','50','40','30']"
                        data-lineheight="['70','60','50','40']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-transform_idle="o:1;"
                        data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:1.5;sY:1.5;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
                        data-transform_out="auto:auto;s:1000;e:Power3.easeInOut;"
                        data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                        data-mask_out="x:0;y:0;s:inherit;e:inherit;"
                        data-start="1000"
                        data-splitin="none"
                        data-splitout="none"
                        data-responsive_offset="on">
                        THE CONSTRUCTION
                    </div>
                    <div class="tp-caption tp-resizeme text-white text-shadow letter-spacing-7px"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['20','20','20','20']"
                        data-fontsize="['28','24','20','18']"
                        data-lineheight="['38','34','30','28']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-transform_idle="o:1;"
                        data-transform_in="y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:1500;e:Power3.easeInOut;"
                        data-transform_out="auto:auto;s:1000;e:Power3.easeInOut;"
                        data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                        data-mask_out="x:0;y:0;s:inherit;e:inherit;"
                        data-start="1000"
                        data-splitin="none"
                        data-splitout="none"
                        data-responsive_offset="on"
                        data-lasttriggerstate="reset">
                        Leading the way in building
                    </div>
                    <div class="tp-caption"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['120','110','100','90']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-transform_idle="o:1;"
                        data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                        data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);cursor:pointer;"
                        data-transform_in="y:50px;opacity:0;s:1500;e:Power4.easeInOut;"
                        data-transform_out="y:[175%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                        data-start="1250"
                        data-splitin="none"
                        data-splitout="none"
                        data-actions='[{"event":"click","action":"scrollbelow","offset":"20px"}]'
                        data-responsive="on">
                        <router-link to="/portfolio" class="wprt-button big rounded-3px">OUR PORTFOLIO</router-link>
                    </div>
                </li>

                <li data-transition="boxfade" data-slotamount="7" data-easein="Expo.easeOut" data-easeout="Expo.easeOut" data-masterspeed="2000" data-thumb="assets/img/slider/2.jpg" data-rotate="0" data-saveperformance="off" data-title="BEST COMPANY">
                    <img src="/assets/img/slider/2.jpg" alt="" data-bgposition="center center" data-no-retina>
                    <div class="tp-caption tp-resizeme text-white font-family-heading text-shadow font-weight-500"
                        data-x="['left','left','left','left']" data-hoffset="['50','30','30','30']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['-100','-90','-80','-70']"
                        data-fontsize="['60','50','40','30']"
                        data-lineheight="['70','60','50','40']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-transform_idle="o:1;"
                        data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:1.5;sY:1.5;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
                        data-transform_out="auto:auto;s:1000;e:Power3.easeInOut;"
                        data-mask_in="x:0px;y:0px;"
                        data-mask_out="x:inherit;y:inherit;"
                        data-start="1500"
                        data-splitin="none"
                        data-splitout="none"
                        data-responsive_offset="on">
                        THE BEST COMPANY
                    </div>
                    <div class="tp-caption tp-resizeme text-white text-shadow"
                        data-x="['left','left','left','left']" data-hoffset="['55','30','30','30']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['-20','-20','-20','-20']"
                        data-fontsize="['20','18','16','14']"
                        data-lineheight="['30','28','26','24']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-transform_idle="o:1;"
                        data-transform_in="y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:1500;e:Power3.easeInOut;"
                        data-transform_out="auto:auto;s:1000;e:Power3.easeInOut;"
                        data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                        data-mask_out="x:0;y:0;s:inherit;e:inherit;"
                        data-start="1000"
                        data-splitin="none"
                        data-splitout="none"
                        data-responsive_offset="on"
                        data-lasttriggerstate="reset">
                        We are professional company over 20 year of experience.<br>We will brighten up every corner of your house.
                    </div>
                    <div class="tp-caption"
                        data-x="['left','left','left','left']" data-hoffset="['55','30','30','30']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['80','70','60','50']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-transform_idle="o:1;"
                        data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                        data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);cursor:pointer;"
                        data-transform_in="y:50px;opacity:0;s:1500;e:Power4.easeInOut;"
                        data-transform_out="y:[175%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                        data-start="1250"
                        data-splitin="none"
                        data-splitout="none"
                        data-actions='[{"event":"click","action":"scrollbelow","offset":"20px"}]'
                        data-responsive="on">
                        <router-link to="/contacts" class="wprt-button big rounded-3px">CONTACT US</router-link>
                    </div>
                </li>
            </ul>
            <div class="tp-bannertimer tp-bottom"></div>
          </div>
        </div>
        <div id="main-content" class="site-main clearfix">
    <div id="content-wrap">
        <div id="site-content" class="site-content clearfix">
            <div id="inner-content" class="inner-content-wrap">
                <div class="page-content">
                    <section class="wprt-section intro">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40"></div>
                                </div>

                                <div class="col-md-4">

                                    <div class="service-item clearfix text-center">
                                        <div class="thumb"><img src="/assets/img/services/4.jpg" alt="image" /></div>
                                        <div class="service-item-wrap">
                                            <h3 class="title font-size-18"><router-link to="/services">Construction Consultant</router-link></h3>
                                            <p class="desc">
                                              We provide guidance and advice or building and house construction.
                                            </p>
                                            <div class="link">
                                                <router-link to="/services" class="wprt-button small rounded-3px">READ MORE</router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="40" data-smobi="40"></div>
                                </div>

                                <div class="col-md-4">
                                    <div class="service-item clearfix text-center">
                                        <div class="thumb"><img src="/assets/img/services/5.jpg" alt="image" /></div>
                                        <div class="service-item-wrap">
                                            <h3 class="title font-size-18"><router-link to="/services">Resconstruction Services</router-link></h3>
                                            <p class="desc">
                                              Reconstruction is a double work, and we know what to do to prevent it in the future
                                            </p>
                                            <div class="link">
                                                <router-link to="/services" class="wprt-button small rounded-3px">READ MORE</router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="40" data-smobi="40"></div>
                                </div>

                                <div class="col-md-4">
                                    <div class="service-item clearfix text-center">
                                        <div class="thumb"><img src="/assets/img/services/6.jpg" alt="image" /></div>
                                        <div class="service-item-wrap">
                                            <h3 class="title font-size-18"><router-link to="/services">Architectural Design</router-link></h3>
                                            <p class="desc">
                                              Any construction project requires and needs an architectural design. It's as simple as that.
                                            </p>
                                            <div class="link">
                                                <router-link to="/services" class="wprt-button small rounded-3px">READ MORE</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40"></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="works" class="wprt-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="wprt-spacer" data-desktop="100" data-mobi="40" data-smobi="40"></div>

                                    <h2 class="text-center margin-bottom-20">RECENT WORKS</h2>
                                    <div class="wprt-lines style-2 custom-1">
                                        <div class="line-1"></div>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="36" data-mobi="30" data-smobi="30"></div>
                                </div>
                            </div>
                        </div>

                        <div class="wprt-project" data-layout="grid" data-column="4" data-column2="3" data-column3="2" data-column4="1" data-gaph="0" data-gapv="0">
                            <div id="project-filter">
                                <div data-filter="*" class="cbp-filter-item">
                                    <span>All</span>
                                </div>
                                <div data-filter=".architecture" class="cbp-filter-item">
                                    <span>Architecture</span>
                                </div>
                                <div data-filter=".building" class="cbp-filter-item">
                                    <span>Building</span>
                                </div>
                                <div data-filter=".garden" class="cbp-filter-item">
                                    <span>Garden</span>
                                </div>
                                <div data-filter=".interior" class="cbp-filter-item">
                                    <span>Interior</span>
                                </div>
                                <div data-filter=".office" class="cbp-filter-item">
                                    <span>Office</span>
                                </div>
                                <div data-filter=".workspace" class="cbp-filter-item">
                                    <span>Workspace</span>
                                </div>
                            </div>

                            <div id="projects" class="cbp">
                                <div class="cbp-item architecture interior workspace">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-zoe">
                                                <img src="/assets/img/projects/1.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/1-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="cbp-item building office workspace">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-honey">
                                                <img src="/assets/img/projects/2.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/2-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="cbp-item architecture garden interior">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-oscar">
                                                <img src="/assets/img/projects/3.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/3-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="cbp-item building interior workspace">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-sadie">
                                                <img src="/assets/img/projects/4.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/4-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="cbp-item garden office workspace">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-zoe">
                                                <img src="/assets/img/projects/5.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/5-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="cbp-item architecture garden office">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-honey">
                                                <img src="/assets/img/projects/6.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/6-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="cbp-item architecture garden office">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-oscar">
                                                <img src="/assets/img/projects/7.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/7-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="cbp-item architecture garden office">
                                    <div class="project-item">
                                        <div class="inner">
                                            <div class="grid">
                                            <figure class="effect-sadie">
                                                <img src="/assets/img/projects/8.jpg" alt="image" />
                                                <figcaption>
                                                    <div>
                                                        <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                        <p>Construction</p>
                                                    </div>
                                                </figcaption>           
                                            </figure>
                                            </div>

                                            <a class="project-zoom cbp-lightbox" href="assets/img/projects/8-full.html" data-title="LUXURY BUILDINGS">
                                                <i class="fa fa-arrows-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="features" class="wprt-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="wprt-spacer" data-desktop="100" data-mobi="40" data-smobi="40"></div>
                                    <h2 class="text-center margin-bottom-20">WHAT WE OFFER</h2>
                                    <div class="wprt-lines style-2 custom-1">
                                        <div class="line-1"></div>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="36" data-mobi="30" data-smobi="30"></div>

                                    <p class="wprt-subtitle">
                                      We offer a wide variety of construction services, from an empty land to a fully furnished building 
                                      or house
                                    </p>
                                    <div class="wprt-spacer" data-desktop="40" data-mobi="30" data-smobi="30"></div>
                                </div>

                                <div class="col-md-2">
                                    <div class="wprt-icon-text">
                                        <div class="icon">
                                            <i class="icon-o-helmet"></i>
                                        </div>
                                        <h3><a href="#">CONSTRUCTION</a></h3>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                </div>

                                <div class="col-md-2">
                                    <div class="wprt-icon-text">
                                        <div class="icon">
                                            <i class="icon-o-paint-roller"></i>
                                        </div>
                                        <h3><a href="#">RENOVATION</a></h3>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                </div>

                                <div class="col-md-2">
                                    <div class="wprt-icon-text">
                                        <div class="icon">
                                            <i class="icon-o-ruler-2"></i>
                                        </div>
                                        <h3><a href="#">CONSULTING</a></h3>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                </div>

                                <div class="col-md-2">
                                    <div class="wprt-icon-text">
                                        <div class="icon">
                                            <i class="icon-o-tools-1"></i>
                                        </div>
                                        <h3><a href="#">CONSTRUCT</a></h3>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                </div>

                                <div class="col-md-2">
                                    <div class="wprt-icon-text">
                                        <div class="icon">
                                            <i class="icon-o-drawing-1"></i>
                                        </div>
                                        <h3><a href="#">ARCHITECTURE</a></h3>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                </div>

                                <div class="col-md-2">
                                    <div class="wprt-icon-text">
                                        <div class="icon">
                                            <i class="icon-o-light-bulb-1"></i>
                                        </div>
                                        <h3><a href="#">ELECTRICAL</a></h3>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="wprt-spacer" data-desktop="118" data-mobi="80" data-smobi="50"></div>

                                    <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                        <div class="icon-wrap font-size-45">
                                            <span class="dd-icon icon-drill-2"></span>
                                        </div>
                                        <div class="content-wrap">
                                            <h3 class="dd-title font-size-18"><a href="#">Prepair Services</a></h3>
                                            <p>
                                              Prepaired services that meet your personal needs; customized services of high quality.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="43" data-mobi="30" data-smobi="20"></div>

                                    <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                        <div class="icon-wrap font-size-35">
                                            <span class="dd-icon icon-tap-1"></span>
                                        </div>
                                        <div class="content-wrap">
                                            <h3 class="dd-title font-size-18"><a href="#">Creative Plumbing</a></h3>
                                            <p>
                                              Leave your leaking pipes and other related plumbing issues to us, everyting will be fixed.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="wprt-spacer" data-desktop="118" data-mobi="30" data-smobi="20"></div>

                                    <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                        <div class="icon-wrap font-size-35">
                                            <span class="dd-icon icon-paint-roller-1"></span>
                                        </div>
                                        <div class="content-wrap">
                                            <h3 class="dd-title font-size-18"><a href="#">Wall Painting</a></h3>
                                            <p>
                                              Let's brighten every corner of your house with our painting crew
                                            </p>
                                        </div>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="43" data-mobi="30" data-smobi="20"></div>

                                    <div class="wprt-icon-box accent-background rounded icon-effect-2 icon-left">
                                        <div class="icon-wrap font-size-35">
                                            <span class="dd-icon icon-roof"></span>
                                        </div>
                                        <div class="content-wrap">
                                            <h3 class="dd-title font-size-18"><a href="#">Metal Roofing</a></h3>
                                            <p>
                                              We take care of your roofing against rain and sunshine. Just what you need
                                              to feel comfortable.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="20"></div>
                                </div>

                                <div class="col-md-4">
                                    <img src="/assets/img/man.png" alt="image" />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section id="promotion" class="wprt-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="wprt-spacer" data-desktop="8" data-mobi="0" data-smobi="0"></div>
                                    <div class="wprt-spacer" data-desktop="0" data-mobi="20" data-smobi="20"></div>
                                </div>

                                <div class="col-md-4">
                                    <div class="text-right text-center-mobile">
                                      <router-link to="/contacts" class="wprt-button white rounded-3px">GET A QUOTE</router-link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section id="why-us" class="wprt-section">
                        <div class="container-fluid no-padding">
                            <div class="row no-margin">
                                <div class="col-md-6 no-padding">
                                    <img src="/assets/img/group.jpg" alt="image">
                                </div>

                                <div class="col-md-6 no-padding">
                                    <div class="wprt-content-box style-2">
                                        <h2 class="margin-bottom-20">WHY CHOOSE US?</h2>
                                        <div class="wprt-lines style-1 custom-2">
                                            <div class="line-1"></div>
                                        </div>
                                        <div class="wprt-spacer" data-desktop="50" data-mobi="30" data-smobi="30"></div>

                                        <div class="wprt-toggle bg-white style-1 active">
                                            <h3 class="toggle-title">Employees have many years of experience</h3>
                                            <div class="toggle-content">
                                              From the carpenter, electrician to macon, we many years of experience in the construction industry. 
                                              We have proven that the largest and complicated construction project can be handled with knowledge and experience.
                                            </div>
                                        </div>

                                        <div class="wprt-toggle bg-white style-1">
                                            <h3 class="toggle-title">Quality construction continues after the project</h3>
                                            <div class="toggle-content">
                                              Whenever we have a new construction project, we ensure quality construction continues in every aspect.
                                              As the project is on course, we mesure progress using quality as measurement. 
                                            </div>
                                        </div>

                                        <div class="wprt-toggle bg-white style-1">
                                            <h3 class="toggle-title">We use technology to do the job more quickly</h3>
                                            <div class="toggle-content">
                                              In the era technology and digitalization, it's very important that no job is delayed. That's why we heavily make use of 
                                              technology to speed things up and do the job well
                                            </div>
                                        </div>

                                        <div class="wprt-toggle bg-white style-1">
                                            <h3 class="toggle-title">Employees are continually trained on safety issues</h3>
                                            <div class="toggle-content">
                                              The safety of our employees and third parties is our priority. We continuously provide training on safety measures to inform
                                              and educate on any on site accident that might occure before, during or after a project.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Team />
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import Team from '@/components/Team.vue'
export default {
    name: 'Home',
    components: {
        Team
    },
}
</script>
